* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
footer {
  background: #7f89a0;
}
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex_box,
.flex_box_vertical {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.flex_box_vertical {
  flex-direction: column;
}
.main_bg_image {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: -1;
  background-image: url("./assets/images/Tumar_Ibragimov_2018.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.main_bg_image .cover {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.statistics_chart_wrapper {
  width: calc(50% - 10px);
  padding: 24px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  background-color: #ffffff;
  position: relative;
}
.pie_chart_legend {
  position: absolute;
  top: calc(50% + 18px);
  left: 40%;
  transform: translateY(-50%);
}
.circle_icon {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border-radius: 50%;
}
.unsplash {
  width: calc(50% - 22px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("./assets/images/unsplash_login.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.blue_cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1058fb;
  opacity: 0.7;
}
.course_paper {
  width: 100%;
  position: relative;
  border: 1px solid #dddddd;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
  align-items: flex-start;
}
.course_cover {
  width: 100%;
  height: 468px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.section_number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
}
.status_passed {
  color: #00b98b;
  background: rgba(103, 206, 103, 0.3);
}
.status_available {
  color: #1058fb;
  background: #c6e9fa;
}
.status_not_available {
  color: #757575;
  background: #eaeaea;
}
.custom_chip {
  padding: 6px 12px;
  font-family: "Inter";
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  border-radius: 90px;
}
.close_icon {
  position: absolute;
  top: 20px;
  right: 24px;
  cursor: pointer;
}
.progress_indicator {
  width: 130px;
  height: 32px;
  position: absolute;
  top: 20px;
  right: 20px;
  background: #ffffff;
  border-radius: 4px;
  overflow: hidden;
}
.progress_indicator .track {
  width: 100%;
  height: 4px;
  background-color: #d7d7d8;
}
.progress_indicator .bar {
  height: 100%;
  background-color: #2bd328;
}
.dialog_close_icon {
  position: absolute;
  top: 20px;
  right: 24px;
  cursor: pointer;
}
.step_animated_left {
  animation: slideLeft 0.3s ease-out;
}
@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0%);
  }
}
.step_animated_right {
  animation: slideRight 0.3s ease-out;
}
@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
.cyrilic {
  padding-left: 20px;
  list-style-type: none; /* Прячем исходную нумерацию */
}
.cyrilic li {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #757575;
  margin-bottom: 10px;
}
.cyrilic li:hover {
  color: #1058fb;
}
.cyrilic li[data-is-correct="true"] {
  color: #03be43;
}
.cyrilic li[data-is-correct="true"]:hover {
  color: #03be43;
}
.cyrilic li[data-is-correct="false"] {
  color: #ff3b30;
}
.cyrilic li[data-is-correct="false"]:hover {
  color: #ff3b30;
}
.cyrilic li::before {
  margin-right: 10px; /* Расстояние от буквы до текста */
  width: 1em; /* Ширина */
  text-align: right; /* Выравнивание по правому краю */
  display: inline-block; /* Строчно-блочный элемент */
}
.cyrilic li:nth-child(1)::before {
  content: "А.";
}
.cyrilic li:nth-child(2)::before {
  content: "Б.";
}
.cyrilic li:nth-child(3)::before {
  content: "В.";
}
.cyrilic li:nth-child(4)::before {
  content: "Г.";
}
.cyrilic li:nth-child(5)::before {
  content: "Д.";
}
.cyrilic li:nth-child(6)::before {
  content: "Е.";
}
.cyrilic li:nth-child(7)::before {
  content: "Ё.";
}
.cyrilic li:nth-child(8)::before {
  content: "Ж.";
}
.cyrilic li:nth-child(9)::before {
  content: "З.";
}
.cyrilic li:nth-child(10)::before {
  content: "И.";
}
@media (max-width: 600px) {
  .course_cover {
    height: 100px;
  }
}
.step {
  padding: 10px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: calc(100% / 6 - 20px);
}
.step.active {
  border: 2px solid #1058fb;
}
.certificate_wrapper {
  width: 100%;
  height: 595px;
  overflow: hidden;
  position: relative;
  background: #ffffff;
}
.certificate_wrapper .logo {
  width: 90px;
  height: 90px;
  z-index: 100;
}
.certificate_wrapper .triangle {
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  mix-blend-mode: multiply;
}
.triangle.top_left {
  top: 0px;
  left: 0px;
  border-width: 165px 750px 0 0;
  border-color: #78c6f1 transparent transparent transparent;
}
.triangle.top_right {
  top: 0px;
  right: 0px;
  border-width: 0 750px 165px 0;
  border-color: transparent #005d9c transparent transparent;
}
.triangle.bottom_right {
  right: 0px;
  bottom: 0px;
  border-width: 0 0 140px 750px;
  border-color: transparent transparent #78c6f1 transparent;
}
.triangle.bottom_left {
  left: 0px;
  bottom: 0px;
  border-width: 140px 0 0 750px;
  border-color: transparent transparent transparent #005d9c;
}
